import {BaseMessageTypes} from "@/shared/ui/BaseMessage/types";

export interface State {
    accessToken: string|null

}

export enum GettersTypes {
    GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN',
}

export enum MutationsTypes {
    SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
}

export interface Getters<S = State> {
    [GettersTypes.GET_ACCESS_TOKEN](state: S): string|null
}

export interface Mutations<S = State> {
    [MutationsTypes.SET_ACCESS_TOKEN](state: S, payload: string|null): void
}